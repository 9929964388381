import { action, makeObservable, observable } from "mobx";

import { compareArrays, compareSimple, generateId } from "@viuch/shared/utils/data";

import { IndexingEntityMetadata } from "../indexing/IndexingEntityMetadata";

export class Subsection implements Subsection.Init {
    readonly id: number;

    @observable name: string;
    @observable sections: number[];
    @observable tags: string[];
    @observable themes: number[];
    readonly video_count;
    readonly synopsis_count;
    readonly task_count;
    readonly problem_count;
    readonly unverified_task_count;
    readonly indexing: IndexingEntityMetadata;

    constructor(init: Subsection.Init) {
        this.id = init.id;
        this.name = init.name;
        this.sections = init.sections.slice();
        this.tags = init.tags.slice();
        this.themes = init.themes.slice();
        this.video_count = init.video_count;
        this.synopsis_count = init.synopsis_count;
        this.task_count = init.task_count;
        this.problem_count = init.problem_count;
        this.unverified_task_count = init.unverified_task_count;
        this.indexing = init.indexing;

        makeObservable(this);
    }

    @action.bound
    setName(name: string) {
        this.name = name;
    }

    clone() {
        const {
            id,
            name,
            sections,
            tags,
            themes,
            video_count,
            synopsis_count,
            task_count,
            problem_count,
            unverified_task_count,
            indexing,
        } = this;

        return new Subsection({
            id,
            name,
            sections,
            tags,
            themes,
            video_count,
            synopsis_count,
            task_count,
            problem_count,
            unverified_task_count,
            indexing: indexing.clone(),
        });
    }

    equalsTo(other: Subsection.Init): boolean {
        switch (false) {
            case compareSimple(this.name, other.name):
            case compareArrays(this.sections, other.sections):
            case compareArrays(this.tags, other.tags):
            case this.indexing.equalsTo(other.indexing):
                return false;
        }
        return true;
    }

    @action.bound
    addTheme(themeId: number) {
        if (!this.themes.includes(themeId)) {
            this.themes.push(themeId);
        }
    }
}

export namespace Subsection {
    export type Init = {
        id: number;
        name: string;
        sections: number[];
        tags: string[];
        themes: number[];
        video_count?: number;
        synopsis_count?: number;
        task_count?: number;
        problem_count?: number;
        unverified_task_count?: number;
        indexing: IndexingEntityMetadata;
    };

    export const getDefaultInit = (override?: Partial<Init>): Init => ({
        id: generateId(),
        name: "",
        sections: [],
        tags: [],
        themes: [],
        indexing: new IndexingEntityMetadata(null, null, ""),
        ...override,
    });
}
