import type { TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";
import { equationMathControl, variableMathControl } from "../controls/value";

export const fillMissingNumbers: TFormControl = {
    control: "Form",
    layout: "column",
    condition: { "./solver_type": ["elementary_equation"] },
    controls: [createLabel("Находит пропущенное число"), equationMathControl],
};

export const equation: TFormControl = {
    control: "Form",
    layout: "column",
    condition: {
        "./solver_type": [
            "equation_linear", //
            "equation_quadratic",
            "equation_cubic",
            "equation_viet",
            "equation_any",
        ],
    },
    controls: [
        createLabel("Решает линейное уравнение", { "./solver_type": "equation_linear" }),
        createLabel("Решает квадратное уравнение", { "./solver_type": "equation_quadratic" }),
        createLabel("Решает кубическое уравнение", { "./solver_type": "equation_cubic" }),
        createLabel("Решает уравнение по формуле Виета", { "./solver_type": "equation_viet" }),
        createLabel("Решает уравнение", { "./solver_type": "equation_any" }),
        equationMathControl,
        variableMathControl,
    ],
};
