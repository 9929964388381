import { action, computed, makeObservable, observable } from "mobx";

import { instantiateForm } from "@viuch/form-builder/serialization/instantiateForm";
import { createSerializedContainer } from "@viuch/math-editor";
import { generateId } from "@viuch/shared/utils/data";
import { equalsDeep } from "@viuch/shared/utils/equalsDeep";

import type { ListItemStore } from "../../views/Hierarchy/components/trees/ListItemStore";
import type { VirtualFormTree } from "@viuch/form-builder/tree/VirtualFormTree";
import type { TSerializedState } from "@viuch/math-editor";

import { serializeTheme } from "../../services/hierarchy/themes";
import { rendererConfigFormSchema } from "../../services/tasks/utils/schema/solverRequestFormSchema";
import { IndexingEntityMetadataOnly } from "../indexing/IndexingEntityMetadataOnly";
import { PrimaryHints } from "../tasks/PrimaryHints";

export class Theme implements ListItemStore.IEntity<Theme> {
    readonly $$instanceId = generateId();

    readonly id: number;
    @observable name: string;
    @observable dependent_themes: number[];
    @observable is_hidden: boolean;
    @observable is_verified: boolean;
    @observable related_themes: number[];
    @observable subsections: number[];
    @observable synopsis?: number;
    @observable task_quantity: number;
    @observable.ref base_task_formula: TSerializedState;
    @observable video?: number;
    @observable task_count: number;
    @observable variant_examples: number[];
    @observable grades: number[];
    @observable slug: string;
    @observable.ref primaryHints: PrimaryHints | null;
    readonly problem_count;
    readonly unverified_task_count;

    @observable.ref indexing: IndexingEntityMetadataOnly;
    readonly inherited_geometry_theorems: number[];
    @observable.shallow geometry_theorems: number[];
    @observable.shallow imageMaterialIds: number[];

    readonly rendererConfigForm: VirtualFormTree;

    constructor(init: Theme.Init) {
        this.id = init.id;
        this.name = init.name;
        this.base_task_formula = init.base_task_formula;
        this.is_hidden = init.is_hidden;
        this.is_verified = init.is_verified;
        this.task_quantity = init.task_quantity;
        this.dependent_themes = init.dependent_themes.slice();
        this.related_themes = init.related_themes.slice();
        this.subsections = init.subsections.slice();
        this.variant_examples = init.variant_examples.slice();
        this.synopsis = init.synopsis;
        this.video = init.video;
        this.task_count = init.task_count;
        this.problem_count = init.problem_count;
        this.grades = init.grades.slice();
        this.slug = init.slug;
        this.indexing = init.indexing;
        this.primaryHints = init.primaryHints;
        this.rendererConfigForm = instantiateForm(rendererConfigFormSchema);
        this.inherited_geometry_theorems = init.inherited_geometry_theorems.slice();
        this.geometry_theorems = init.geometry_theorems.slice();
        this.imageMaterialIds = init.imageMaterialIds.slice();
        this.unverified_task_count = init.unverified_task_count;

        this.rendererConfigForm.setSerializedState(init.renderer_config ?? { division_representation: "division" });

        makeObservable(this);
    }

    @action.bound
    setName(name: string) {
        this.name = name;
    }

    @action.bound
    setHidden(hidden: boolean) {
        this.is_hidden = hidden;
    }

    @action.bound
    setVerified(verified: boolean) {
        this.is_verified = verified;
    }

    @action.bound
    setSlug(slug: string) {
        this.slug = slug;
    }

    clone() {
        const {
            rendererConfigForm,
            primaryHints,
            indexing,
            base_task_formula,
            video,
            synopsis,
            subsections,
            is_hidden,
            is_verified,
            grades,
            slug,
            name,
            related_themes,
            dependent_themes,
            task_quantity,
            task_count,
            variant_examples,
            problem_count,
            id,
            inherited_geometry_theorems,
            geometry_theorems,
            imageMaterialIds,
            unverified_task_count,
        } = this;

        return new Theme({
            renderer_config: rendererConfigForm.getSerializedState(),
            primaryHints,
            indexing,
            base_task_formula,
            video,
            synopsis,
            subsections,
            is_hidden,
            is_verified,
            grades,
            slug,
            name,
            related_themes,
            dependent_themes,
            task_quantity,
            task_count,
            variant_examples,
            problem_count,
            id,
            inherited_geometry_theorems,
            geometry_theorems,
            imageMaterialIds,
            unverified_task_count,
        });
    }

    @action.bound
    addGeometryTheorem(theoremId: number) {
        if (this.geometry_theorems.includes(theoremId)) return;

        this.geometry_theorems.push(theoremId);
    }

    @action.bound
    removeGeometryTheorem(theoremId: number) {
        this.geometry_theorems.remove(theoremId);
    }

    equalsTo(other: Theme): boolean {
        return equalsDeep(serializeTheme(this), serializeTheme(other));
    }

    @action.bound
    setTaskQuantity(taskQuantity: number) {
        this.task_quantity = taskQuantity;
    }

    @action.bound
    addSubsection(id: number) {
        if (!this.subsections.includes(id)) {
            this.subsections.push(id);
        }
    }

    @action.bound
    removeSubsection(id: number) {
        const i = this.subsections.indexOf(id);
        if (i !== -1) {
            this.subsections.splice(i, 1);
        }
    }

    @action.bound
    addRelatedTheme(id: number) {
        if (!this.related_themes.includes(id)) {
            this.related_themes.push(id);
        }
    }

    @action.bound
    removeRelatedTheme(id: number) {
        const i = this.related_themes.indexOf(id);
        if (i !== -1) {
            this.related_themes.splice(i, 1);
        }
    }

    @action.bound
    addDependentTheme(id: number) {
        if (!this.dependent_themes.includes(id)) {
            this.dependent_themes.push(id);
        }
    }

    @action.bound
    removeDependentTheme(id: number) {
        const i = this.dependent_themes.indexOf(id);
        if (i !== -1) {
            this.dependent_themes.splice(i, 1);
        }
    }

    @action.bound
    removeVideo(videoId: number) {
        if (this.video === videoId) {
            this.video = void 0;
        }
    }

    @action.bound
    addVideo(videoId: number) {
        this.video = videoId;
    }

    @computed get videos(): number[] {
        return this.video ? [this.video] : [];
    }

    @action.bound
    removeSynopsis(synopsisId: number) {
        if (this.synopsis === synopsisId) {
            this.synopsis = void 0;
        }
    }

    @action.bound
    addSynopsis(synopsisId: number) {
        this.synopsis = synopsisId;
    }

    @computed get synopsesVirtual(): number[] {
        return this.synopsis ? [this.synopsis] : [];
    }

    @action.bound
    addTaskVariant(id: number) {
        if (!this.variant_examples.includes(id)) {
            this.variant_examples.push(id);
        }
    }

    @action.bound
    removeTaskVariant(id: number) {
        const i = this.variant_examples.indexOf(id);
        if (i !== -1) {
            this.variant_examples.splice(i, 1);
        }
    }

    @action.bound
    setGradeIds(gradeIds: number[]) {
        this.grades = gradeIds;
    }

    @action.bound
    setBaseTaskFormula(value: TSerializedState) {
        this.base_task_formula = value;
    }

    @action.bound
    enablePrimaryHints() {
        this.primaryHints ??= new PrimaryHints([]);
    }

    @action.bound
    removePrimaryHints() {
        this.primaryHints = null;
    }

    @action.bound
    setImageMaterialIds(ids: number[]) {
        this.imageMaterialIds = ids;
    }
}

export namespace Theme {
    export type Init = {
        id: number;
        name: string;
        related_themes: number[];
        dependent_themes: number[];
        task_quantity: number;
        is_verified: boolean;
        base_task_formula: TSerializedState;
        video?: number;
        synopsis?: number;
        subsections: number[];
        is_hidden: boolean;
        task_count: number;
        problem_count?: number;
        variant_examples: number[];
        grades: number[];
        slug: string;
        indexing: IndexingEntityMetadataOnly;
        primaryHints: PrimaryHints | null;
        renderer_config: object | null;
        inherited_geometry_theorems: number[];
        geometry_theorems: number[];
        imageMaterialIds: number[];
        unverified_task_count?: number;
    };

    export const getDefaultInit = (override?: Partial<Init>): Init => ({
        id: generateId(),
        name: "",
        dependent_themes: [],
        is_hidden: false,
        is_verified: false,
        related_themes: [],
        subsections: [],
        task_quantity: 10,
        base_task_formula: createSerializedContainer(),
        task_count: 0,
        video: void 0,
        synopsis: void 0,
        variant_examples: [],
        grades: [],
        slug: "",
        indexing: new IndexingEntityMetadataOnly(null, null),
        primaryHints: null,
        renderer_config: null,
        geometry_theorems: [],
        inherited_geometry_theorems: [],
        imageMaterialIds: [],
        ...override,
    });
}
