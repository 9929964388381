import { action, makeObservable, observable } from "mobx";

import { generateId } from "@viuch/shared/utils/data";

export class MeasureUnit implements MeasureUnit.Init {
    readonly id;
    readonly dimensionId;
    @observable name;
    @observable sign;
    @observable k;

    constructor(init: MeasureUnit.Init) {
        this.id = init.id;
        this.name = init.name;
        this.sign = init.sign;
        this.k = init.k;
        this.dimensionId = init.dimensionId;

        makeObservable(this);
    }

    @action.bound
    setName(name: string) {
        this.name = name;
    }

    @action.bound
    setSign(sign: string) {
        this.sign = sign;
    }

    @action.bound
    setK(k: number) {
        this.k = k;
    }
}

export namespace MeasureUnit {
    export type Init = {
        id: number;
        name: string;
        sign: string;
        k: number;
        dimensionId: number;
    };

    export const createDefaultInit = (override: Partial<Init> & Pick<Init, "dimensionId">): Init => ({
        id: generateId(),
        name: "",
        sign: "",
        k: 1,
        ...override,
    });
}
