import { action, makeObservable, observable } from "mobx";

import { compareArrays, compareSimple, generateId } from "@viuch/shared/utils/data";

import { IndexingEntityMetadata } from "../indexing/IndexingEntityMetadata";

export class Section implements Section.Init {
    readonly id: number;

    @observable name: string;
    @observable subjects: number[];
    @observable subsections: number[];
    @observable description: string;
    readonly theme_count;
    readonly video_count;
    readonly synopsis_count;
    readonly task_count;
    readonly problem_count;
    readonly unverified_task_count: number | undefined;
    readonly indexing: IndexingEntityMetadata;

    constructor(init: Section.Init = Section.getDefaultInit()) {
        this.id = init.id;
        this.name = init.name;
        this.subjects = init.subjects.slice();
        this.subsections = init.subsections.slice();
        this.theme_count = init.theme_count;
        this.video_count = init.video_count;
        this.synopsis_count = init.synopsis_count;
        this.task_count = init.task_count;
        this.problem_count = init.problem_count;
        this.description = init.description;
        this.unverified_task_count = init.unverified_task_count;
        this.indexing = init.indexing;

        makeObservable(this);
    }

    equalsTo(other: Section.Init): boolean {
        switch (false) {
            case compareSimple(this.name, other.name):
            case compareSimple(this.description, other.description):
            case compareArrays(this.subjects, other.subjects):
            case this.indexing.equalsTo(other.indexing):
                return false;
        }
        return true;
    }

    @action.bound
    setName(name: string) {
        this.name = name;
    }

    clone() {
        const {
            id,
            name,
            subjects,
            subsections,
            description,
            theme_count,
            video_count,
            synopsis_count,
            task_count,
            problem_count,
            unverified_task_count,
            indexing,
        } = this;

        return new Section({
            id,
            name,
            subjects,
            subsections,
            description,
            theme_count,
            video_count,
            synopsis_count,
            task_count,
            problem_count,
            unverified_task_count,
            indexing: indexing.clone(),
        });
    }

    @action.bound
    addSubsection(subsectionId: number) {
        if (!this.subsections.includes(subsectionId)) {
            this.subsections.push(subsectionId);
        }
    }

    @action.bound
    setDescription(description: string) {
        this.description = description;
    }
}

export namespace Section {
    export type Init = {
        id: number;
        name: string;
        subjects: number[];
        subsections: number[];
        description: string;
        theme_count?: number;
        video_count?: number;
        synopsis_count?: number;
        task_count?: number;
        problem_count?: number;
        unverified_task_count?: number;
        indexing: IndexingEntityMetadata;
    };

    export const getDefaultInit = (override?: Partial<Init>): Init => ({
        id: generateId(),
        name: "",
        subjects: [],
        subsections: [],
        description: "",
        indexing: new IndexingEntityMetadata(null, null, ""),
        ...override,
    });
}
